.nosotros {
  /* margin: 40px auto; */
  text-align: center;
  font-style: italic;
}

.nosotros img {
  margin-bottom: 40px;
}

@media screen and (min-width: 600px) {
  .nosotros img {
    border-radius: 25px;
  }
}
