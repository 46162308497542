.header {
  padding: 0 15px;
}

.logo {
  margin: 30px auto;
  display: block;
}

.header-links {
  display: flex;
  justify-content: space-around;
  padding-bottom: 10px;
}
