.menu-item {
  display: flex;
  justify-content: space-between;
  /* align edges with back button */
  padding: 0 15px 20px;
}

.menu-item-description {
  opacity: 0.5;
}

.menu-item-price {
  font-size: 16px;
  opacity: 0.7;
}
