.menu {
  margin-top: 0;
}

.crumbs-wrapper {
  text-align: center;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  /* align edges with menu items */
  padding: 0 15px;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  margin: 30px 0;
  font-size: 17px;
}

.crumbs-wrapper.hidden {
  opacity: 0;
}

.menu-container {
  padding-left: 15px;
  padding-right: 15px;
}

.menu-family-back {
  cursor: pointer;
  width: 70px;
  transition: opacity 0.2s ease-in-out;
  opacity: 0.5;
}

.menu-family-back:hover {
  opacity: 1;
}

.menu-family-back.hidden {
  opacity: 0;
}

.menu-family .skeleton-box {
  width: 150px;
}

.menu-family .skeleton-box.image {
  height: 150px;
}
