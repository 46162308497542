.empanadas {
  text-align: center;
}

.empanadas img {
  margin-bottom: 40px;
}

@media screen and (min-width: 600px) {
  .empanadas img {
    border-radius: 25px;
  }
}
