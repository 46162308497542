.section {
  background: #fbfbfb;
}

.section-title {
  color: rgb(111, 33, 30);
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  background: white;
  height: 160px;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  margin: 0;
}

.section-title .b-container {
  display: flex;
  align-items: center;
}

.section-title-logo {
  width: 35px;
  height: 35px;
  margin-right: 10px;
}

.section-body p:last-child {
  margin: 0;
  padding-bottom: 1rem;
}

@media screen and (min-width: 600px) {
  .section-body {
    padding: 50px 0;
  }
}
