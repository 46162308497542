.map {
  display: block;
  aspect-ratio: 1.3;
  width: 100%;
  margin-bottom: 40px;
}

@media screen and (min-width: 600px) {
  .map {
    border-radius: 25px;
  }
}

.address {
  text-align: center;
}

.footer-links {
  display: flex;
  justify-content: space-between;
}

.footer-links a {
  color: rgba(0, 0, 0, 0.5);
  font-size: 19px;
}

@media screen and (max-width: 599px) {
  .footer-links {
    flex-wrap: wrap;
    padding: 50px 50px 20px;
    gap: 10px;
  }
}
