.menu-families {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 30px;
}

.menu-family {
  text-align: center;
  margin-bottom: 30px;
  cursor: pointer;
  width: 180px;
  transform: scale(1);
  transition: transform 0.5s ease-in-out;
}

.menu-family:hover {
  transform: scale(1.1);
}

.menu-family img {
  max-width: 150px;
  border-radius: 25px;
}
